import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, post } from "../../../common/api";
import { API_SUBSCRIPTION_PLANS, API_ADD_CUSTOMER_SUBSCRIPTION, API_CLIENT_SECRET_KEY, API_ADD_CUSTOMER_PAYMENT_METHOD_SUBSCRIPTION } from "../../../common/api/constants";


export const actionSubscriptionPlan = createAsyncThunk('appSubscriptionPlans/actionSubscriptionPlan', async (params, thunkAPI) => {
    try {
        return await post(API_SUBSCRIPTION_PLANS, params, thunkAPI)
    } catch (e) {
        // console.log("errrrr", e);
    }
})

export const actionAddCustomerSubscription = createAsyncThunk('appSubscriptionPlans/actionAddCustomerSubscription', async (params, thunkAPI) => {
    try {
        return await post(API_ADD_CUSTOMER_SUBSCRIPTION, params, thunkAPI)
    } catch (e) {
        // console.log("errrrr", e);
    }
})

export const actionClientSecretKey = createAsyncThunk('payments/actionClientSecretKey', async () => {
    try {
        return await get(API_CLIENT_SECRET_KEY)
    } catch (error) {
        // console.log("errrrr", e);
    }
})

export const actionAddPaymentMethodSubscription = createAsyncThunk('payments/actionAddPaymentMethodSubscription', async (params) => {
    try {
        return await post(API_ADD_CUSTOMER_PAYMENT_METHOD_SUBSCRIPTION, params)
    } catch (error) {
        // console.log("errrrr", e);
    }
})


export const appSubscriptionPlansSlice = createSlice({
    name: 'appSubscriptionPlans',
    initialState: {
        loading: false,
        subscriptionPlansResponse: null,

        loadingAddSubscriptionPlan: false,
        addSubscriptionPlanResponse: null,

        loadingClientSecretKey: true,
        clientSecretKey: null,
        paymentMethodClientSecretKey: null,
        signupSubscriptionPlanClientSecretKey: null,

        loadingAddPaymentMethodSubscription: true,
        addPaymentMethodSubscription: null,

    },
    reducers: {
        resetSubscriptionPlansResponse: (state) => {
            state.subscriptionPlansResponse = null
        },
        resetAddSubscriptionPlanResponse: (state) => {
            state.addSubscriptionPlanResponse = null
        },
        resetClientSecretKey: (state) => {
            state.clientSecretKey = null
        },
        resetPaymentMethodClientSecretKey: (state) => {
            state.paymentMethodClientSecretKey = null
        },
        resetSignupSubscriptionPlanClientSecretKey: (state) => {
            state.signupSubscriptionPlanClientSecretKey = null
        },
        resetAddPaymentMethodSubscription: (state) => {
            state.addPaymentMethodSubscription = null
        }
    },
    extraReducers: builder => {
        builder
            .addCase(actionSubscriptionPlan.pending, (state, action) => {
                state.loading = true
            })
            .addCase(actionSubscriptionPlan.fulfilled, (state, action) => {
                state.loading = false
                state.subscriptionPlansResponse = action.payload
            }).addCase(actionSubscriptionPlan.rejected, (state, action) => {
                state.loading = false
            })

            .addCase(actionAddCustomerSubscription.pending, (state, action) => {
                state.loading = true
            })
            .addCase(actionAddCustomerSubscription.fulfilled, (state, action) => {
                state.loading = false
                state.addSubscriptionPlanResponse = action.payload
            }).addCase(actionAddCustomerSubscription.rejected, (state, action) => {
                state.loading = false
            })

            //Client Secret Key
            .addCase(actionClientSecretKey.pending, (state) => {
                state.loadingClientSecretKey = true
            })
            .addCase(actionClientSecretKey.fulfilled, (state, action) => {
                state.loadingClientSecretKey = false
                if (action.meta.arg === 'PaymentMethod') {
                    state.paymentMethodClientSecretKey = action.payload
                }
                else if (action.meta.arg === 'SignupSubscriptionPlan') {
                    state.signupSubscriptionPlanClientSecretKey = action.payload
                } else {
                    state.clientSecretKey = action.payload
                }
            })
            .addCase(actionClientSecretKey.rejected, (state) => {
                state.loadingClientSecretKey = false
            })

            //Add Payment Method Subscription
            .addCase(actionAddPaymentMethodSubscription.pending, (state) => {
                state.loadingAddPaymentMethodSubscription = true
            })
            .addCase(actionAddPaymentMethodSubscription.fulfilled, (state, action) => {
                state.loadingAddPaymentMethodSubscription = false
                state.addPaymentMethodSubscription = action.payload
            })
            .addCase(actionAddPaymentMethodSubscription.rejected, (state) => {
                state.loadingAddPaymentMethodSubscription = false
            })
    }
})
export const {
    resetSubscriptionPlansResponse,
    resetAddSubscriptionPlanResponse,
    resetAddPaymentMethodSubscription,
    resetClientSecretKey,
    resetPaymentMethodClientSecretKey,
    resetSignupSubscriptionPlanClientSecretKey
} = appSubscriptionPlansSlice.actions
export default appSubscriptionPlansSlice.reducer